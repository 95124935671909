<template>
  <div class="section">
    <h2 class="mt-8">This is the contact us page.</h2>
  </div>
</template>

<script>
export default {

}
</script>

<style>
  .section{padding: 5px 65px 5px 65px;
  text-align: center;}
</style>